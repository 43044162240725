import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import ReactPlayer from "react-player";

import { ReactComponent as PlayIcon } from "../../assets/images/icons/play-btn.svg";
import { ReactComponent as PauseIcon } from "../../assets/images/icons/pause-btn.svg";

const styles = {
	root: {
		display: "flex",
		width: "100%",
		height: "100%"
	},
	video: {
		width: "100%",
		height: "100%",
		maxWidth: 1024,
		margin: "auto",
		cursor: "pointer"
	},
	actions: {
		// position: "absolute",
		// top: 0,
		// left: 0,
		// width: "100%",
		// height: "100%"
	},
	btn: {
		display: "none",
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(0,0,0,0.3)"
	},
	show: {
		display: "block !important"
	},
	icon: {
		position: "absolute",
		top: "calc( 50% - 50px )",
		left: "calc( 50% - 50px )",
		width: 100,
		height: 100,
		color: "#fff",
		fill: "#fff",
		cursor: "pointer"
	}
};

class Controls extends Component {
	state = {
		hiddenPlay: false,
		hiddenPause: true,
		controls: false,
		playing: false
	};

	play = () => {
		this.setState(
			() => ({
				hiddenPlay: true,
				hiddenPause: true,
				controls: true,
				playing: true
			}),
			() => {
				console.log(this.state);
			}
		);
	};

	render() {
		const { classes, src, stop } = this.props;
		const { hiddenPause, hiddenPlay, controls, playing } = this.state;
		return (
			<div className={classNames(classes.root)}>
				<ReactPlayer
					className={classNames(classes.video, "app-video")}
					url={src}
					controls={controls}
					playing={playing && !stop}
					width="100%"
					height="100%"
					onPause={() =>
						this.setState({
							hiddenPlay: false,
							hiddenPause: true
						})
					}
					onPlay={() =>
						this.setState({
							hiddenPlay: true,
							hiddenPause: true
						})
					}
					onClick={() =>
						this.setState(state => ({
							hiddenPause: true,
							hiddenPlay: !state.hiddenPlay,
							playing: !state.playing,
							controls: true
						}))
					}
					// onReady={() => this.setState({ controls: true })}
				/>
				<div
					className={classNames(classes.actions)}
					// style={{ zIndex: hiddenPlay ? -1 : 0 }}
				>
					<div
						className={classNames(classes.btn, hiddenPlay ? "" : classes.show)}
						onClick={() =>
							this.setState({
								hiddenPlay: true,
								hiddenPause: true,
								controls: true,
								playing: true
							})
						}
					>
						<PlayIcon className={classes.icon} onClick={this.play} />
					</div>
					<div
						className={classNames(classes.btn, hiddenPause ? "" : classes.show)}
						onClick={() =>
							this.setState({
								hiddenPlay: false,
								hiddenPause: true,
								playing: false
							})
						}
					>
						<PauseIcon className={classes.icon} />
					</div>
				</div>
			</div>
		);
	}
}

Controls.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Controls);
