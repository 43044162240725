export default () => ({

	/*
	 * Badge: {
	 * 	right: -15,
	 * 	top: 1
	 * },
	 * btn: {
	 * 	marginRight: 13
	 * },
	 * menuItem: {
	 * 	minWidth: 200
	 * }
	 */
});
