import React from "react";
import { Route, Switch } from "react-router-dom";
import loadable from "react-loadable";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import NotFound from "../../containers/not-found";

const Welcome = loadable({
	loader: () => import("../../containers/welcome"),
	loading: () => null,
	modules: ["welcome"]
});

const Main = loadable({
	loader: () => import("../../containers/layout/Layout"),
	loading: () => null,
	modules: ["main"]
});

const Poi = loadable({
	loader: () => import("../../containers/poi"),
	loading: () => null,
	modules: ["poi"]
});

const Routes = () => (
	<TransitionGroup>
		<CSSTransition classNames="fade" timeout={1000}>
			<Switch>
				<Route exact path="/" component={Welcome} />
				<Route exact path="/:batiment" component={Main} />
				<Route exact path="/:batiment/:poi" component={Poi} />
				<Route component={NotFound} />
			</Switch>
		</CSSTransition>
	</TransitionGroup>
);

export default Routes;
