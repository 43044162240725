import { SET_CAROUSEL } from "./constants";

import initialState from "./initialState";

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_CAROUSEL:
			return {
				...initialState
			};

		default:
			return state;
	}
};
