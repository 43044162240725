export const card = theme => ({
	"@global": {
		".benefitWrapper": {
			alignItems: "normal !important"
		}
	},
	card: {
		backgroundColor: theme.palette.common.white,
		marginBottom: theme.spacing.unit,
		"&:hover": {
			boxShadow: theme.shadows[3],
			cursor: "pointer"
		},
		"&:last-child": {
			marginBottom: 0
		}
	},
	cardHeader: {
		// alignItems: "start",
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${
			theme.spacing.unit
		}px ${theme.spacing.unit}px`
	},
	cardContent: {
		padding: `${theme.spacing.unit * 0}px ${theme.spacing.unit * 2}px ${
			theme.spacing.unit
		}px 0`,
		"&:last-child": {
			paddingBottom: theme.spacing.unit
		},
		marginLeft: 35,
		"@media (max-width:1440px)": {
			marginLeft: 0
		},
		"@media (max-width:960px)": {
			marginLeft: 35
		}
	},
	cardHeaderContent: {
		marginRight: theme.spacing.unit
	},
	cardAction: {
		boxShadow: "none",
		width: 24,
		height: 24,
		minHeight: "auto",
		"@media (max-width:1440px)": {
			width: 16,
			height: 16
		},
		"@media (max-width:960px)": {
			width: 24,
			height: 24
		}
	},
	btn: {
		fontSize: 16,
		fill: "#888888",
		width: 16,
		height: 16,
		"@media (max-width:1440px)": {
			width: 10,
			height: 10
		},
		"@media (max-width:960px)": {
			width: 16,
			height: 16
		}
		// backgroundColor: theme.palette.background.paper
	},
	avatar: {
		// fontSize: "0.55rem",
		fontWeight: 600,
		width: 35,
		height: 35,
		"@media (max-width:1440px)": {
			width: 30,
			height: 30
		},
		"@media (max-width:960px)": {
			width: 35,
			height: 35
		}
	},
	avatarRoot: {
		marginRight: theme.spacing.unit
	},
	title: {
		fontWeight: 600,
		color: "#000"
	},
	[theme.breakpoints.down("md")]: {
		"@global": {
			".benefitWrapper": {
				marginRight: "0px !important"
			}
		}
	}
});

export const item = theme => ({
	wrapper: {
		display: "flex",
		alignItems: "center",
		margin: `${theme.spacing.unit}px 0`,
		width: "50%",
		flexBasis: "50%"
	},
	icon: {
		display: "inline-flex",
		width: 24,
		height: 24,
		marginRight: theme.spacing.unit,
		fill: "#cccccc",
		flexShrink: 0,
		"@media (max-width:1440px)": {
			width: 18,
			height: 18
		},
		"@media (max-width:960px)": {
			width: 24,
			height: 24
		}
	},
	text: {
		display: "inline-flex",
		color: "#888888",
		fontSize: "0.9rem",
		fontWeight: 600,
		"@media (max-width:1440px)": {
			fontSize: "0.7rem"
		},
		"@media (max-width:960px)": {
			fontSize: "0.9rem"
		}
		// textTransform: "capitalize"
	}
});

export const content = theme => ({
	wrapper: {
		display: "flex",
		flexWrap: "wrap"
		// "@media (max-width:1440px)": {
		// 	marginLeft: -theme.spacing.unit,
		// 	width: `calc(100% + ${theme.spacing.unit}px)`
		// },
		// "@media (max-width:1366px)": {
		// 	marginLeft: -theme.spacing.unit * 2,
		// 	width: `calc(100% + ${theme.spacing.unit * 2}px)`
		// },
		// "@media (max-width:960px)": {
		// 	marginLeft: 0,
		// 	width: "auto"
		// }
	}
});
