import React from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import {
	withStyles
} from "@material-ui/core";

import { connect } from "react-redux";

import { ReactComponent as LogoIcon } from "../../assets/images/vectuel.svg";

class Logo extends React.Component {

	render () {
		const { classes, isMobile } = this.props;
		return (
			<div className={classes.wrapper}>
				{(isMobile) ? null : (
					<span className={classes.text}>
						{"Une solution "}
					</span>
				)}
				<LogoIcon className={classes.icon} />
			</div>
		);
	}

}

const mapStateToProps = (state) => ({
	...state.helpers
});

Logo.propTypes = {
	classes: PropTypes.object.isRequired,
	isMobile: PropTypes.bool.isRequired
};

export default withStyles(styles)(connect(mapStateToProps)(Logo));
