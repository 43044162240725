import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { radio as styles } from "./styles";

import {
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	FormHelperText,
	Checkbox as MuiCheckbox,
	Fab,

	withStyles
} from "@material-ui/core";

class Radio extends React.Component {

	check = (key) => {
		const { name, filters } = this.props;
		const itemName = name.toLowerCase();

		if (key === filters[itemName]) {
			return true;
		}
		return false;
	}

	renderCheckbox () {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<FormControl
					className={classes.formControl}
					component="fieldset"
				>
					<FormLabel component="legend">
						{"Gender"}
					</FormLabel>
					<RadioGroup
						aria-label="Gender"
						className={classes.group}
						name="gender1"
						onChange={this.handleChange}
						value={this.state.value}
					>
						<FormControlLabel
							control={<MuiCheckbox />}
							label="Female"
							value="female"
						/>
						<FormControlLabel
							control={<MuiCheckbox />}
							label="Male"
							value="male"
						/>
						<FormControlLabel
							control={<MuiCheckbox />}
							label="Other"
							value="other"
						/>
						<FormControlLabel
							control={<MuiCheckbox />}
							disabled
							label="(Disabled option)"
							value="disabled"
						/>
					</RadioGroup>
				</FormControl>
				<FormControl
					className={classes.formControl}
					component="fieldset"
				>
					<FormLabel component="legend">
						{"Gender"}
					</FormLabel>
					<RadioGroup
						aria-label="gender"
						className={classes.group}
						name="gender2"
						onChange={this.handleChange}
						value={this.state.value}
					>
						<FormControlLabel
							control={<MuiCheckbox color="primary" />}
							label="Female"
							labelPlacement="start"
							value="female"
						/>
						<FormControlLabel
							control={<MuiCheckbox color="primary" />}
							label="Male"
							labelPlacement="start"
							value="male"
						/>
						<FormControlLabel
							control={<MuiCheckbox color="primary" />}
							label="Other"
							labelPlacement="start"
							value="other"
						/>
						<FormControlLabel
							control={<MuiCheckbox />}
							disabled
							label="(Disabled option)"
							labelPlacement="start"
							value="disabled"
						/>
					</RadioGroup>
					<FormHelperText>
						{"labelPlacement start"}
					</FormHelperText>
				</FormControl>
			</div>
		);
	}

	render () {
		const { classes, values, onClick, name } = this.props;

		if ([
			"type",
			"floor"
		].indexOf(name) < 0) {
			return this.renderCheckbox();
		}

		return values.map((action) => {
			if (name === "floor") {
				action.label = (
					<span className="superE">
						{action.key}
					</span>
				);
				if (Number(action.key) === 0) {
					action.label = "RDC";
				}
				if (action.key === "all") {
					action.label = "tous";
				}
				if (Number(action.key) === 1) {
					action.label = (
						<span className="superER">
							{action.key}
						</span>
					);
				}
			}
			return (
				<Fab
					className={classNames(classes.action, action.key, this.check(action.key) ? "active" : "")}
					key={action.key}
					onClick={(event) => onClick(event, action.key)}
					value={action.key}
				>
					{action.label}
				</Fab>
			);
		});
	}

}

Radio.propTypes = {
	classes: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	values: PropTypes.array.isRequired
};

export default withStyles(styles)(Radio);
