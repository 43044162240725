const config = {
	palette: {
		primary: "#0397AC",
		secondary: "#3D454B",
		type: "light"
	},
	frame: 0,
	title: "Naturessence"
};

export const { title, frame, palette } = config;

export default config;
