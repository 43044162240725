import React from "react";

const NotFound = () => (
	<>
		{"Page not found"}
	</>
);

export default NotFound;
export { NotFound };
