export default theme => ({
	root: {
		width: "100vw",
		height: "100vh"
	},
	"@global": {
		"@font-face": [
			{
				fontFamily: "Questrial",
				src: [
					"url('assets/fonts/hinted-Questrial-Regular.eot')",
					"url('assets/fonts/hinted-Questrial-Regular.eot?#iefix') format('embedded-opentype')",
					"url('assets/fonts/hinted-Questrial-Regular.woff2') format('woff2')",
					"url('assets/fonts/hinted-Questrial-Regular.woff') format('woff')",
					"url('assets/fonts/hinted-Questrial-Regular.ttf') format('truetype')",
					"url('assets/fonts/hinted-Questrial-Regular.svg#Questrial-Regular') format('svg')"
				],
				fontWeight: "normal",
				fontStyle: "normal"
			}
		],
		html: {
			"-webkit-font-smoothing": "antialiased",
			"-moz-osx-font-smoothing": "grayscale",
			fontFamily: "Questrial,Arial,sans,sans-serif",
			fontSize: "0.8vw",
			// "@media (max-width:1920px)": {
			// 	fontSize: 16
			// },
			// "@media (max-width:1440px)": {
			// 	fontSize: 14
			// },
			// "@media (max-width:1366px)": {
			// 	fontSize: 13
			// },
			"@media (max-width:960px)": {
				fontSize: 12
			},
			// "@media (max-width:768px)": {
			// 	fontSize: 10
			// },
			// "@media (max-width:320px)": {
			// 	fontSize: 10
			// }
			"& *": {
				scrollBehavior: "smooth",
				scrollbarWidth: "thin"
			}
		},
		body: {
			width: "100vw",
			height: "100vh",
			overflow: "hidden"
		},
		".backgroundColor1": {
			backgroundColor: `${theme.app.backgroundColor1} !important`
		},
		".backgroundColor2": {
			backgroundColor: `${theme.app.backgroundColor2} !important`
		},
		".backgroundColor3": {
			backgroundColor: `${theme.app.backgroundColor3} !important`
		},
		".backgroundColor4": {
			backgroundColor: `${theme.app.backgroundColor4} !important`
		},
		".backgroundColor5": {
			backgroundColor: `${theme.app.backgroundColor5} !important`
		},
		".fade-enter": {
			opacity: 0,
			zIndex: 1
		},
		".fade-enter.fade-enter-active": {
			opacity: 1,
			transition: "opacity 250ms ease-in"
		},
		".squared": {
			"&:after": {
				content: "'2'",
				display: "inline-block",
				fontSize: "0.7em",
				verticalAlign: "super"
			},
			"&.withMargin:after": {
				marginBottom: theme.spacing.unit
			}
		},
		".superE": {
			"&:after": {
				content: "'e'",
				display: "inline-block",
				fontSize: "0.7em",
				verticalAlign: "super",
				textTransform: "lowercase"
			},
			"&.withMargin:after": {
				marginBottom: theme.spacing.unit
			}
		},
		".superER": {
			"&:after": {
				content: "'er'",
				display: "inline-block",
				fontSize: "0.7em",
				verticalAlign: "super",
				textTransform: "lowercase"
			},
			"&.withMargin:after": {
				marginBottom: theme.spacing.unit
			}
		},
		".benefitWrapper": {
			display: "flex !important",
			flexDirection: "column",
			alignItems: "center",
			marginRight: -theme.spacing.unit * 6
		}
	},
	[theme.breakpoints.down("sm")]: {
		root: {
			overflowY: "auto"
		},
		body: {
			overflowY: "auto"
		}
	}
});
