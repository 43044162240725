export default () => ({
	root: {
		borderRadius: 100,
		padding: "4px 4px 0px 4px"
	},
	button: {
		display: "inline-flex",
		marginRight: 4,
		"&:last-child": {
			marginRight: 0
		},
		"&:hover": {
			cursor: "pointer"
		}
	}
});
