import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createHashHistory, createMemoryHistory } from "history";
import createRootReducer from "./modules";

export const isServer = !(
	typeof window !== "undefined" &&
	window.document &&
	window.document.createElement
);

export default (url = "/") => {
	const history = isServer
		? createMemoryHistory({
				initialEntries: [url]
		  })
		: createHashHistory();

	const enhancers = [];

	// eslint-disable-next-line
	if (process.env.NODE_ENV === "development" && !isServer) {
		const { devToolsExtension } = window;

		if (typeof devToolsExtension === "function") {
			enhancers.push(devToolsExtension());
		}
	}

	const middleware = [
		thunk,
		routerMiddleware(history),
		store => next => action => {
			action.location = store.getState().router.location;
			next(action);
		}
	];
	const composedEnhancers = compose(
		applyMiddleware(...middleware),
		...enhancers
	);

	const initialState = isServer ? {} : window.__PRELOADED_STATE__;

	if (!isServer) {
		delete window.__PRELOADED_STATE__;
	}

	const store = createStore(
		createRootReducer(history),
		initialState,
		composedEnhancers
	);

	return {
		store,
		history
	};
};
