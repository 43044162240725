import React, { Component } from "react";
import { Slider as RcSlider, Rail, Handles, Tracks } from "react-compound-slider";
import { SliderRail, Handle, Track } from "./partials";

const sliderStyle = {
	position: "relative",
	width: "100%",
	marginTop: "20px"
};

class Example extends Component {

	render () {
		return (
			<RcSlider
				rootStyle={sliderStyle}
				{...this.props}
			>
				<Rail>
					{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
				</Rail>
				<Handles>
					{({ handles, getHandleProps }) => (
						<div className="slider-handles">
							{handles.map((handle) => (
								<Handle
									key={handle.id}
									handle={handle}
									domain={this.props.domain}
									getHandleProps={getHandleProps}
								/>
							))}
						</div>
					)}
				</Handles>
				<Tracks
					left={false}
					right={false}
				>
					{({ tracks, getTrackProps }) => (
						<div className="slider-tracks">
							{tracks.map(({ id, source, target }) => (
								<Track
									key={id}
									source={source}
									target={target}
									getTrackProps={getTrackProps}
								/>
							))}
						</div>
					)}
				</Tracks>
			</RcSlider>
		);
	}

}

export default Example;
