import {
	SET_CANVAS_IMAGES,
	TOGGLE_LIGHTS,
	SET_FRAME,
	SET_CANVAS
} from "./constants";

export const setCanvas = routeName => dispatch =>
	new Promise(resolve => {
		dispatch({
			type: SET_CANVAS,
			routeName
		});
		resolve(routeName);
	});

export const getCanvas = ({ state }) => () =>
	new Promise(resolve => {
		resolve(state);
	});

export const setCanvasImages = images => (dispatch, getState) =>
	new Promise(resolve => {
		const { building } = getState().helpers;
		dispatch({
			type: SET_CANVAS_IMAGES,
			images,
			building
		});

		resolve(images);
	});

export const toggleLights = () => (dispatch, getState) =>
	new Promise(resolve => {
		const { building } = getState().helpers;
		dispatch({
			type: TOGGLE_LIGHTS,
			building
		});

		resolve(true);
	});

export const setFrame = frame => (dispatch, getState) =>
	new Promise(resolve => {
		const { building } = getState().helpers;
		dispatch({
			type: SET_FRAME,
			frame,
			building
		});

		resolve(frame);
	});
