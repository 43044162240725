import React, { Component } from "react";
import PropTypes from "prop-types";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { red } from "@material-ui/core/colors";

import CssBaseline from "@material-ui/core/CssBaseline";

import config from "../config";

const theme = createMuiTheme({
	typography: {
		fontFamily: ["Questrial", "Arial", "sans", "sans-serif"].join(","),
		fontSize: 12,
		htmlFontSize: 12,
		h1: {
			fontSize: "1.5rem",
			fontWeight: 500
		},
		h2: {
			fontSize: "1.2rem",
			fontWeight: 500
		},
		h3: {
			fontSize: "1rem",
			fontWeight: 500
		},
		h4: {
			fontSize: "0.9rem",
			fontWeight: 500
		},
		h5: {
			fontSize: "0.7rem",
			fontWeight: 500
		},
		h6: {
			fontSize: "0.5rem"
		},
		useNextVariants: true
	},
	overrides: {
		MuiButton: {
			text: {
				// fontSize: "0.7rem"
			}
		}
	},
	spacing: {
		unit: 8
	},
	mixins: {
		toolbar: {
			minHeight: "50px",
			height: "50px"
		},
		"@media (min-width:0px) and (orientation: landscape)": {
			minHeight: "50px"
		},
		"@media (min-width:600px)": {
			minHeight: "50px"
		}
	},
	app: {
		backgroundColor1: "#82E0AA",
		backgroundColor2: "#BB8FCE",
		backgroundColor3: "#f8c471",
		backgroundColor4: "#ec7063",
		backgroundColor5: "#3498db",
		default: config.palette.primary
	},
	palette: {
		contrastThreshold: 3,
		error: red,
		common: {
			black: "#3D454B",
			white: "#ffffff"
		},
		background: {
			paper: "#f3efed",
			default: "#ffffff"
		},
		primary: {
			main: config.palette.primary
		},
		secondary: {
			main: config.palette.secondary
		},
		tonalOffset: 0.3,
		type: config.palette.type
	}
});

class ThemeProvider extends Component {
	render() {
		const { children } = this.props;
		return (
			<MuiThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</MuiThemeProvider>
		);
	}
}

ThemeProvider.propTypes = {
	children: PropTypes.any.isRequired
};

export default ThemeProvider;
export { theme };
