import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { duration } from "@material-ui/core/styles/transitions";

import { Modal, Fade, Fab, Paper, Button, IconButton } from "@material-ui/core";
import Dots from "./Dots";
import classNames from "classnames";
import View from "./View";

import { ReactComponent as LeftArrowIcon } from "../../assets/images/icons/left-arrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/images/icons/right-arrow.svg";
import { Close as CloseIcon } from "@material-ui/icons";

const correction = (a, n) => ((a % n) + n) % n;

const styles = {
	root: {
		"& > *:focus": {
			outline: "none"
		},
		width: "100vw",
		height: "100vh",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	content: {
		width: "65vw",
		maxWidth: "65vw",
		height: "auto",
		maxHeight: "70vh",
		margin: "0 auto 0",
		position: "relative"
		// top: "50%",
		// transform: "translateY(-50%)",
		// overflow: "hidden"
	},
	contentMobile: {
		width: "100%",
		height: "100%",
		maxWidth: "initial",
		maxHeight: "initial",
		margin: 0,
		top: 0,
		transform: "none",

		"& > $carouselWrapper": {
			borderRadius: 0
		}
	},
	arrow: {
		width: 48,
		height: 48,
		position: "absolute",
		top: "calc((100% - 96px) / 2 + 24px)",
		background: "none",
		boxShadow: "none",
		"&:hover, &:focus": {
			background: "none"
		}
	},
	arrowLeft: {
		left: -96
	},
	arrowRight: {
		right: -96
	},
	arrowIcon: {
		color: "#fff"
	},
	carouselWrapper: {
		overflow: "hidden",
		transform: "scale(1.0)",
		background: "transparent",
		height: "100%",
		boxShadow: "none"
	},
	dots: {
		margin: "0 auto"
	},
	dotsMobile: {
		paddingTop: 0
	},
	dotsMobileLandscape: {
		paddingTop: 20
	},
	footer: {
		width: "100%",
		textAlign: "center"
	},
	footerMobile: {},
	footerMobileLandscape: {
		marginTop: -3,
		transform: "translateY(-50vh)",
		display: "inline-block",
		width: "auto"
	},
	slide: {
		width: "100%",
		height: "100%",
		overflow: "hidden !important"
	},
	slideMobile: {
		width: "100%",
		height: "100%"
	},
	carousel: {
		height: "100%"
	},
	carouselContainer: {
		height: "100%"
	},
	closed: {},
	closeBtn: {
		color: "#fff",
		position: "absolute",
		top: -40,
		right: -80
	}
};

class Container extends Component {
	state = {
		slideIndex: 0
	};

	handleContentClick = e => e.stopPropagation() || e.preventDefault();

	handleChange = slideIndex => {
		this.setState(
			{
				slideIndex
			},
			this.onChange(slideIndex)
		);
	};

	handleArrowClick(direction) {
		const videos = Array.prototype.slice.call(
			document.getElementsByClassName("app-video")
		);
		if (videos.length) {
			videos.forEach(videoDOM => {
				videoDOM.pause();
			});
		}
		if (direction === -1) {
			return this.decreaseIndex();
		}
		return this.increaseIndex();
	}

	decreaseIndex() {
		console.log("increase");
		this.setState(
			state => ({
				slideIndex: state.slideIndex - 1
			}),
			this.onChange(this.state.slideIndex)
		);
	}

	increaseIndex() {
		this.setState(
			state => ({
				slideIndex: state.slideIndex + 1
			}),
			this.onChange(this.state.slideIndex)
		);
	}

	onChange(slideIndex) {
		if (this.props.onChange) {
			this.props.onChange(correction(slideIndex, this.props.children.length));
		}
	}

	render() {
		const {
			autoplay,
			children,
			classes,
			containerStyle,
			hideArrows,
			interval,
			label,
			landscape: landscapeProp,
			mobile,
			open,
			onClose,
			onStart
		} = this.props;
		const landscape = mobile && landscapeProp;
		const transitionDuration = {
			enter: duration.enteringScreen,
			exit: duration.leavingScreen
		};
		const hasMultipleChildren = children.length != null;

		const carousel = (
			<View
				autoplay={open && autoplay && hasMultipleChildren}
				className={classes.carousel}
				containerStyle={{ height: "100%", ...containerStyle }}
				index={this.state.slideIndex}
				interval={interval}
				onChangeIndex={this.handleChange}
				slideClassName={classes.slide}
			>
				{React.Children.map(children, c =>
					React.cloneElement(c, {
						mobile,
						landscape
					})
				)}
			</View>
		);

		return (
			<div
				className={classNames(classes.root, {
					[classes.rootMobile]: mobile
				})}
				// open={open}
				// onClose={onClose}
				// BackdropProps={{ transitionDuration }}
			>
				<Fade appear in={open} timeout={transitionDuration}>
					<div
						className={classNames(classes.content, {
							[classes.contentMobile]: mobile
						})}
						onClick={this.handleContentClick}
					>
						<Paper
							elevation={mobile ? 0 : 1}
							className={classes.carouselWrapper}
						>
							{carousel}
						</Paper>
						<div
							style={
								landscape
									? {
											minWidth: 300,
											maxWidth: "calc(50% - 48px)",
											padding: 24,
											float: "right"
									  }
									: null
							}
						>
							<div
								className={classNames(classes.footer, {
									[classes.footerMobile]: mobile,
									[classes.footerMobileLandscape]: landscape
								})}
							>
								{label && (
									<Button variant="contained" onClick={onStart}>
										{label}
									</Button>
								)}
								{hasMultipleChildren && (
									<Dots
										count={children.length}
										index={correction(this.state.slideIndex, children.length)}
										className={classNames(classes.dots, {
											[classes.dotsMobile]: mobile,
											[classes.dotsMobileLandscape]: landscape
										})}
										onDotClick={this.handleChange}
									/>
								)}
							</div>
						</div>
						{!mobile && !hideArrows && hasMultipleChildren && (
							<div>
								<Fab
									className={classNames(classes.arrow, classes.arrowLeft)}
									onClick={() => this.handleArrowClick(-1)}
								>
									<LeftArrowIcon className={classes.arrowIcon} />
								</Fab>
								<Fab
									className={classNames(classes.arrow, classes.arrowRight)}
									onClick={() => this.handleArrowClick(1)}
								>
									<RightArrowIcon className={classes.arrowIcon} />
								</Fab>
							</div>
						)}
						<IconButton
							aria-label="Close"
							className={classNames(classes.closeBtn)}
							onClick={() => this.props.handleClose()}
						>
							<CloseIcon fontSize="large" />
						</IconButton>
					</div>
				</Fade>
			</div>
		);
	}
}

Container.defaultProps = {
	autoplay: true,
	interval: 3000,
	mobile: false,
	open: false,
	hideArrows: false
};

Container.propTypes = {
	autoplay: PropTypes.bool,
	classes: PropTypes.object.isRequired,
	containerStyle: PropTypes.object,
	hideArrows: PropTypes.bool,
	interval: PropTypes.number,
	label: PropTypes.string,
	landscape: PropTypes.bool,
	mobile: PropTypes.bool,
	onChange: PropTypes.func,
	onClose: PropTypes.func,
	onStart: PropTypes.func,
	open: PropTypes.bool
};

export default withStyles(styles)(Container);
