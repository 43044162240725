import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles";

import {
	Badge,
	Button,
	Checkbox,
	ListItemText,
	Menu,
	MenuItem,

	withStyles
} from "@material-ui/core";

import * as Icons from "@material-ui/icons";

class Select extends React.Component {

	state = {
		anchorEl: null,
		options: []
	};

	handleClick = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleChange = (value) => () => {
		const { options } = this.state;
		const currentIndex = options.indexOf(value);
		const newChecked = [...options];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		this.setState({
			options: newChecked
		});
	};

	handleClose = () => {
		this.setState({
			anchorEl: null
		});
	};

	render () {
		const { anchorEl } = this.state;
		const { classes, options, title } = this.props;
		return (
			<React.Fragment>
				<Button
					aria-haspopup="true"
					aria-owns={anchorEl ? "simple-menu" : null}
					className={classNames(classes.btn)}
					color="primary"
					onClick={this.handleClick}
					variant="outlined"
				>
					{title}
					<Icons.ArrowDropDown />
				</Button>
				<Menu
					anchorEl={anchorEl}
					id="simple-menu"
					onClose={this.handleClose}
					open={Boolean(anchorEl)}
				>
					{options.map((option) => (
						<MenuItem
							className={classNames(classes.menuItem)}
							key={option.id}
							onClick={this.handleChange(option.id)}
						>
							<Checkbox
								checked={this.state.options.indexOf(option.id) > -1}
								disableRipple
								value={option.id}
							/>
							<Badge
								badgeContent={option.count}
								classes={{ badge: classes.badge }}
								color="primary"
							>
								<ListItemText primary={option.category} />
							</Badge>
						</MenuItem>
					))}
				</Menu>
			</React.Fragment>
		);
	}

}
Select.propTypes = {
	classes: PropTypes.object.isRequired,
	options: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired
};
export default withStyles(styles)(Select);
