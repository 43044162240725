export default theme => ({
	wrapper: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		margin: 0,
		padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit}px`,
		backgroundColor: "rgba(0,0,0,0.5)",
		position: "absolute",
		bottom: 0,
		left: 0
	},
	text: {
		display: "inline-flex",
		color: "#ffffff",
		marginRight: theme.spacing.unit,
		fontSize: "0.9rem",
		fontFamily: "Questrial,Arial,sans,sans-serif"
	},
	icon: {
		display: "inline-flex",
		width: "auto",
		height: 38
	}
});
