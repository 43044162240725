const isMobileInitial = window.innerWidth < 960;

const initialState = {
	window: {
		width: window.innerWidth
	},
	sidebar: {
		hidden: isMobileInitial,
		opened: !isMobileInitial
	},
	search: {
		hidden: true,
		opened: false
	},
	isMobile: isMobileInitial,
	routeName: "/",
	building: "batiment-1"
};

export default initialState;
