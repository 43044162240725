import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import {
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography
} from "@material-ui/core";

import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

import { ReactComponent as TypeIcon } from "../../assets/images/icons/type.svg";
import { ReactComponent as EtageIcon } from "../../assets/images/icons/etage.svg";
import { ReactComponent as BenefitIcon } from "../../assets/images/icons/annexes.svg";
import { ReactComponent as SurfaceIcon } from "../../assets/images/icons/surface2.svg";
import { ReactComponent as BudgetIcon } from "../../assets/images/icons/budget.svg";

import { Filter } from "../index";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { filters } from "../../store";

class Filters extends React.Component {
	state = {
		expanded: true
	};

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleExpandChange = () => {
		this.setState(state => ({
			...state,
			expanded: !state.expanded
		}));
	};

	renderIcon = key => {
		const { classes } = this.props;
		if (key === "type") {
			return <TypeIcon className={classes.icon} />;
		}
		if (key === "price") {
			return <BudgetIcon className={classes.icon} />;
		}
		if (key === "surface") {
			return <SurfaceIcon className={classes.icon} />;
		}
		if (key === "benefits") {
			return <BenefitIcon className={classes.icon} />;
		}
		if (key === "floor") {
			return <EtageIcon className={classes.icon} />;
		}
		return null;
	};

	render() {
		const { classes, list, isMobile } = this.props;
		// @TODO item.values[1].key === 0 FIX This
		const check = item =>
			(!isMobile && (item.key === "type" || item.key === "floor")) ||
			(item.type === "range" && (!item.values[1] || item.values[1].key === 0));
		return (
			<div className={classNames(classes.filters)}>
				{Object.values(list).map(item => {
					if (check(item)) {
						return null;
					}

					return (
						<div className={classNames(classes.filter)} key={item.name}>
							<ExpansionPanel
								classes={{
									root: classes.panelRoot,
									expanded: classes.expanded
								}}
								defaultExpanded={this.state.expanded}
							>
								<ExpansionPanelSummary
									classes={{
										root: classes.panelSummaryRoot,
										expanded: classes.expanded,
										content: classes.content
									}}
									expandIcon={
										<ExpandMoreIcon
											classes={{
												root: classes.panelSummaryIcon
											}}
										/>
									}
								>
									<Typography
										className={classes.panelSummaryHeading}
										variant="h3"
									>
										{this.renderIcon(item.key)}
										{item.label}
									</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails
									classes={{
										root: classes.panelDetails
									}}
								>
									<div className={classes.root}>
										<Filter className={classes.filterAction} item={item} />
									</div>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</div>
					);
				})}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	filtersList: {
		...state.filters[state.helpers.building]
	},
	list: {
		...state.settings[state.helpers.building].filters
	},
	isMobile: state.helpers.isMobile
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			type: filters.setType,
			floor: filters.setFloor,
			benefit: filters.setBenefit,
			price: filters.setPrice,
			surface: filters.setSurface
		},
		dispatch
	);

Filters.propTypes = {
	classes: PropTypes.object.isRequired
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Filters);
