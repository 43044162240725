import db from "../../data/db.json";

const state = Object.keys(db).reduce((initialState, building) => {
	initialState[building] = db[building].medias;
	return initialState;
}, {});

// const initialState = {
// 	image: [
// 		{
// 			title: "Title 1",
// 			src: "data/index.jpg"
// 		},
// 		{
// 			title: "Title 2",
// 			src: "data/index.jpg"
// 		},
// 		{
// 			title: "Title 3",
// 			src: "data/index.jpg"
// 		},
// 		{
// 			title: "Title 4",
// 			src: "data/index.jpg"
// 		},
// 		{
// 			title: "Title 5",
// 			src: "data/index.jpg"
// 		}
// 	],
// 	video: [
// 		{
// 			title: "Title 1",
// 			src: "data/media/video.mp4"
// 		},
// 		{
// 			title: "Title 2",
// 			src: "data/media/video.mp4"
// 		},
// 		{
// 			title: "Title 3",
// 			src: "data/media/video.mp4"
// 		}
// 	],
// 	iframe: [
// 		{
// 			title: "Title 1",
// 			src: "https://app.solen.immo/appartement/X3RLi6BEpuE"
// 		},
// 		{
// 			title: "Title 2",
// 			src: "https://app.solen.immo/appartement/X3RLi6BEpuE"
// 		},
// 		{
// 			title: "Title 3",
// 			src: "https://app.solen.immo/appartement/X3RLi6BEpuE"
// 		}
// 	]
// };

export default state;
