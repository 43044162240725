import { App } from "./containers";
import { register } from "./services/registerServiceWorker";
import createStore from "./store";
import { ThemeProvider } from "./utils";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { hydrate, render } from "react-dom";
import { Frontload } from "react-frontload";
import Loadable from "react-loadable";
import { Provider } from "react-redux";

// Create a store and get back itself and its history object
const { store, history } = createStore();

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Frontload noServerRender>
				<ThemeProvider>
					<App />
				</ThemeProvider>
			</Frontload>
		</ConnectedRouter>
	</Provider>
);

const root = document.querySelector("#root");

if (root.hasChildNodes() === true) {
	// If it's an SSR, we use hydrate to get fast page loads by just
	// attaching event listeners after the initial render
	Loadable.preloadReady().then(() => {
		hydrate(Application, root);
	});
} else {
	// If we're not running on the server, just render like normal
	render(Application, root);
}
register();
