export default theme => ({
	wrapper: {
		overflow: "hidden"
	},
	main: {
		width: "80vw",
		height: "100vh",
		overflow: "hidden",
		position: "relative"
	},
	mainTitle: {
		backgroundColor: "rgba(0,0,0,0.5)",
		color: "#fff",
		position: "absolute",
		top: theme.spacing.unit * 5,
		left: theme.spacing.unit * 5,
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
		textTransform: "uppercase"
	},
	poi: {
		width: "100px",
		height: "100px",
		borderRadius: "100%",
		backgroundColor: theme.palette.common.white,
		display: "flex",
		alignItems: "center",
		position: "absolute",
		"&.poi1": {
			left: "32%",
			top: "66%"
		},
		"&.poi2": {
			left: "57%",
			top: "32%"
		},
		"&:hover $icon": {
			fill: theme.palette.primary.dark
		}
	},
	icon: {
		width: 35,
		height: 35,
		margin: "auto",
		fill: theme.palette.primary.main
	},
	primaryColor: {
		color: theme.palette.primary.main,
		fill: theme.palette.primary.main
	},
	btnBack: {
		backgroundColor: theme.palette.common.white,
		position: "absolute",
		top: 70,
		left: 20,
		zIndex: 1,
		fontSize: "0.9rem",
		padding: `${theme.spacing.unit / 4}px ${theme.spacing.unit + 4}px`,
		minHeight: "auto",
		height: "auto"
	},
	btnBackLabel: {
		color: theme.palette.primary.main,
		fill: theme.palette.primary.main,
		"& svg": {
			color: theme.palette.primary.main,
			fill: theme.palette.primary.main
		},
		fontSize: "0.7rem"
	},
	arrowBackIcon: {
		marginRight: theme.spacing.unit / 2,
		width: "1.1rem",
		height: "1.1rem"
	},
	// btnLight: {
	// 	position: "absolute",
	// 	top: 70,
	// 	right: 20,
	// 	width: 30,
	// 	height: 30,
	// 	minHeight: 30,
	// 	minWidth: "auto",
	// 	padding: 0
	// },
	// lightIcon: {
	// 	width: "1.5em",
	// 	height: "1.5em",
	// 	fill: theme.palette.primary.contrastText,
	// 	stroke: theme.palette.primary.contrastText
	// },
	toolbar: theme.mixins.toolbar,
	[theme.breakpoints.down("sm")]: {
		main: {
			width: "100vw",
			height: "100vh"
		},
		welcome: {
			width: "100vw",
			height: "100vh"
		}
	}
});
