import React from "react";
import PropTypes from "prop-types";
// import classNames from "classnames";

import {
	Typography
} from "@material-ui/core";

const Title = ({ classes }) => (
	<>
		<Typography
			className={classes.title}
			noWrap
			variant="h2"
		>
			{"JE RECHERCHE:"}
		</Typography>
	</>
);

Title.propTypes = {
	classes: PropTypes.object.isRequired
};

export default Title;
