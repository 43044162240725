import db from "../../data/db.json";

const buildings = Object.keys(db);

const state = buildings.reduce((initialState, building) => {
	initialState[building] = db[building].pois;
	return initialState;
}, {});

export default state;
