import classNames from "classnames";
import { popup as styles } from "./styles";
import { withStyles, Grid, Typography } from "@material-ui/core";

import PropTypes from "prop-types";
import React, { Component } from "react";

class Popup extends Component {

	render () {
		const { popup, classes } = this.props;
		const { open, x, y, color, poi } = popup;
		const pieces = poi ? Number(poi.type.replace(/\D/gimu, "")) : 0;
		return (
			<Grid
				className={classes.wrapper}
				container
				direction="row"
				alignItems="center"
				alignContent="center"
				style={{
					display: open ? "flex" : "none",
					border: `1px solid ${color}`,
					top: y,
					left: x,
					boxShadow: "0 0 6px 0 rgba(0,0,0,.3)"
				}}
			>
				<Grid
					className={classes.left}
					item
					xs={4}
					style={{
						backgroundColor: color

					}}
				>
					<Typography
						className={classes.lot}
						variant="h1"
						color="inherit"
					>
						{poi ? poi.lot : null}
					</Typography>
				</Grid>
				<Grid
					className={classes.right}
					item
					xs={8}
				>
					<Typography
						className={classes.value}
						style={{
							color
						}}
						variant="h4"
					>
						{poi ? `${pieces} ${pieces === 1 ? "pièce" : "pièces"}` : null}
					</Typography>
					<Typography
						className={classNames(classes.value, classes.valueBold)}
						style={{
							color
						}}
						variant="h1"
					>
						{poi
							? (
								<span className="squared">
									{`${poi.surface} m`}
								</span>
							)
							: null}
					</Typography>
				</Grid>
			</Grid>
		);
	}

}

Popup.propTypes = {
	classes: PropTypes.object.isRequired,
	popup: PropTypes.object.isRequired
};

export default withStyles(styles)(Popup);
