import PropTypes from "prop-types";
import React from "react";
import styles from "./styles";

import {
	Drawer,
	Typography,
	Fab,
	Button,

	withStyles
} from "@material-ui/core";

import {
	Replay as ReplayIcon
} from "@material-ui/icons";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openSearch } from "../../store/helpers";
import { reset } from "../../store/filters";

import Filters from "./Filters";

class Search extends React.Component {

	render () {
		const { classes, opened, hidden, handleOpenSearch, handleResetFilters } = this.props;

		return (
			<Drawer
				anchor="right"
				classes={{
					paper: classes.drawerPaper,
					root: hidden ? classes.hidden : ""
				}}
				open={opened}
				variant="persistent"
			>
				<div className={classes.toolbar} />
				<div className={classes.wrapper} >
					<Typography
						align="center"
						classes={{
							root: classes.title
						}}
						variant="h2"
					>
						{"RECHERCHE AVANCÉE"}
					</Typography>
					<Filters
						classes={classes}
					/>
					<div className={classes.actions}>
						<Fab
							classes={{
								root: classes.btnApply
							}}
							variant="extended"
							onClick={handleOpenSearch}
						>
							{"appliquer les filtres"}
						</Fab>
						<Button
							classes={{
								root: classes.btnReset
							}}
							onClick={handleResetFilters}
						>
							<ReplayIcon />
							{"réinitialiser"}
						</Button>
					</div>
				</div>
			</Drawer>
		);
	}

}

const mapStateToProps = (state) => ({
	...state.helpers.search
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	handleOpenSearch: openSearch,
	handleResetFilters: reset
}, dispatch);

Search.propTypes = {
	classes: PropTypes.object.isRequired,
	handleOpenSearch: PropTypes.func.isRequired,
	handleResetFilters: PropTypes.func.isRequired,
	hidden: PropTypes.bool.isRequired,
	opened: PropTypes.bool.isRequired
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Search));
