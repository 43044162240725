import PropTypes from "prop-types";
import React from "react";
import { header as styles } from "./styles";
import { withRouter } from "react-router-dom";

import {
	Fab,
	Typography,
	withStyles
} from "@material-ui/core";

import {
	Close as CloseIcon
} from "@material-ui/icons";

class Header extends React.Component {

	render () {
		const { classes, history } = this.props;
		return (
			<div className={classes.toolbar}>
				<div
					className={classes.backActions}
				>
					<Typography>
						{"RETOUR"}
					</Typography>
					<Fab
						className={classes.btnBack}
						onClick={history.goBack}
					>
						<CloseIcon />
					</Fab>
				</div>
			</div>
		);
	}

}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(Header));
