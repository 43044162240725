import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import styles from "./styles";
import { Link } from "react-router-dom";

import { Typography, withStyles } from "@material-ui/core";
import { title } from "../../config";
import { Wrapper } from "../index";
import { ReactComponent as PoiIcon } from "../../assets/images/icons/3d.svg";

class Welcome extends Component {

	render () {
		const { classes } = this.props;

		return (
			<Wrapper className={classes.welcome}>
				<Typography className={classes.title}
variant="h1">
					{title}
				</Typography>
				<Link
					className={classNames(classes.poi, "poi1")}
					to={{
						pathname: "/batiment-1",
						state: {
							prevPath: window.location.pathname
						}
					}}
				>
					<PoiIcon className={classNames(classes.icon)} />
				</Link>
				{/* <Link
					className={classNames(classes.poi, "poi2")}
					to={{
						pathname: "/batiment-2",
						state: {
							prevPath: window.location.pathname
						}
					}}
				>
					<PoiIcon className={classNames(classes.icon)} />
				</Link> */}
			</Wrapper>
		);
	}

}

Welcome.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Welcome);
