import { SET_POIS, SET_POI } from "./constants";
import initialState from "./initialState";

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_POIS:
			return {
				...initialState
			};
		case SET_POI:
			return {
				...state,
				poi: action.poi
			};

		default:
			return state;
	}
};
