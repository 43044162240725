import React from "react";
import PropTypes from "prop-types";
// import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";

const styles = {
	root: {
		height: "100%"
	},
	rootMobileLandscape: {
		width: "100%",
		display: "flex",
		flexDirection: "row"
	},
	media: {
		height: "100%",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"& > *": {
			maxHeight: "100%"
		},
		backgroundColor: "transparent"
	},
	mediaMobile: {
		position: "relative",
		top: "50%",
		transform: "translateY(-50%)"
	},
	mediaMobileLandscape: {},
	mediaBackground: {
		height: "100% !important",
		textAlign: "center"
	},
	mediaBackgroundMobile: {
		height: "100%"
	},
	mediaBackgroundMobileLandscape: {
		height: "100%",
		flex: "1 1",
		alignSelf: "stretch"
	},
	text: {
		textAlign: "center",
		maxWidth: "80%",
		margin: "0 auto",
		paddingTop: 32
	},
	textMobile: {
		paddingTop: 30,
		"& $title": {
			marginBottom: 8
		}
	},
	textMobileLandscape: {
		minWidth: 300,
		maxWidth: "calc(50% - 48px)",
		padding: "24px 24px 128px",
		flex: "0 1",
		alignSelf: "center",
		textAlign: "left",
		margin: 0
	}
};

const Slide = props => {
	const { classes, media, mobile, landscape, ...other } = props;

	const mobileLandscape = mobile && landscape;

	return (
		<div
			className={classNames(classes.root, {
				[classes.rootMobile]: mobile,
				[classes.rootMobileLandscape]: mobileLandscape
			})}
			{...other}
		>
			<div
				className={classNames(classes.mediaBackground, {
					[classes.mediaBackgroundMobile]: mobile,
					[classes.mediaBackgroundMobileLandscape]: mobileLandscape
				})}
				// style={mediaBackgroundStyle}
			>
				<div
					className={classNames(classes.media, {
						[classes.mediaMobile]: mobile,
						[classes.mediaMobileLandscape]: mobileLandscape
					})}
				>
					{media}
				</div>
			</div>
		</div>
	);
};

Slide.propTypes = {
	classes: PropTypes.object.isRequired,
	media: PropTypes.node.isRequired
};

export default withStyles(styles)(Slide);
