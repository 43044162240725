import React, { Component } from "react";
import PropTypes from "prop-types";
import { Fab, withStyles } from "@material-ui/core";
import classNames from "classnames";

import { ReactComponent as ImageIcon } from "../../assets/images/icons/mountains.svg";
import { ReactComponent as VideoIcon } from "../../assets/images/icons/play.svg";
import { ReactComponent as ThreeDIcon } from "../../assets/images/icons/cube.svg";

import { controls as styles } from "./styles";

class Controls extends Component {
	render() {
		const { classes, onClick, medias } = this.props;
		return (
			<div className={classNames(classes.root)}>
				<p className={classes.title}>{"Immergez VOUS"}</p>
				<div className={classes.actions}>
					{Object.keys(medias).map(type => {
						const list = medias[type];
						if (list.length < 1) {
							return null;
						}
						return (
							<Fab
								key={`js-btn-${type}`}
								className={classes.btn}
								color="primary"
								onClick={() => onClick(type)}
							>
								{type === "image" ? (
									<ImageIcon className={classes.btnIcon} />
								) : null}
								{type === "video" ? (
									<VideoIcon className={classes.btnIcon} />
								) : null}
								{type === "iframe" ? (
									<ThreeDIcon className={classes.btnIcon} />
								) : null}
							</Fab>
						);
					})}
				</div>
			</div>
		);
	}
}

Controls.propTypes = {
	classes: PropTypes.object.isRequired,
	medias: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired
};

export default withStyles(styles)(Controls);
