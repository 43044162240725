import {
	SET_WINDOW_WIDTH,
	SET_ROUTE,
	SET_SEARCH_HIDDEN,
	SET_SIDEBAR_HIDDEN,
	SET_SEARCH_OPENED,
	SET_SIDEBAR_OPENED,
	SET_MOBILE
} from "./constants";
import { canvas, filters, pois, settings } from "../index";

export const setRoute = routeName => dispatch =>
	new Promise(resolve => {
		const building = routeName.split("/")[0];
		const checkBuilding = /batiment-./gimu.test(building);
		dispatch({
			type: SET_ROUTE,
			routeName,
			building: checkBuilding ? building : "batiment-1"
		});
		// // console.log("routeName", routeName);
		// if () {
		// 	dispatch(settings.setSettings(routeName));
		// 	dispatch(filters.setFilters(routeName));
		// 	dispatch(pois.setPois(routeName));
		// 	dispatch(canvas.setCanvas(routeName));
		// }
		resolve(routeName);
	});

export const windowSizeChange = ({ state }) => dispatch =>
	new Promise(resolve => {
		dispatch({
			type: SET_WINDOW_WIDTH,
			width: window.innerWidth
		});
		resolve(state);
	});

export const setSearchHidden = hidden => dispatch =>
	new Promise(resolve => {
		dispatch({
			type: SET_SEARCH_HIDDEN,
			hidden
		});
		resolve(hidden);
	});

export const setSidebarHidden = hidden => dispatch =>
	new Promise(resolve => {
		dispatch({
			type: SET_SIDEBAR_HIDDEN,
			hidden
		});
		resolve(hidden);
	});

export const openSearch = () => dispatch =>
	new Promise(resolve => {
		dispatch({
			type: SET_SEARCH_OPENED
		});
		resolve();
	});

export const openSidebar = () => dispatch =>
	new Promise(resolve => {
		dispatch({
			type: SET_SIDEBAR_OPENED
		});
		resolve();
	});

export const setMobile = isMobile => dispatch =>
	new Promise(resolve => {
		dispatch({
			type: SET_MOBILE,
			isMobile
		});
		resolve();
	});
