import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import styles from "./styles";
import Container from "./Container";
import Slide from "./Slide";

import { withStyles, Fab, Paper, Button, IconButton } from "@material-ui/core";
import Dots from "./Dots";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import TabBar from "rc-tabs/lib/TabBar";
import "rc-tabs/assets/index.css";

import { ReactComponent as LeftArrowIcon } from "../../assets/images/icons/left-arrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/images/icons/right-arrow.svg";
import { Close as CloseIcon } from "@material-ui/icons";

import Controls from "./Controls";
import Player from "../player";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { filters } from "../../store";
const correction = (a, n) => ((a % n) + n) % n;

class Carousel extends Component {
	state = {
		open: false,
		stop: false,
		keys: {
			image: 0,
			iframe: 0,
			video: 0
		}
	};

	handleOpen = (type, event) => {
		if (event) {
			this.handleContentClick(event);
		}
		this.setState({
			stop: true
		});

		this.setState(
			state => ({
				[type]: !state[type]
			}),
			() => {
				if (type === "video" && this.state[type] === true) {
					this.setState({
						stop: false
					});
				}
			}
		);
	};

	handleContentClick = e => e.stopPropagation() || e.preventDefault();

	handleChange = (type, value, event) => {
		this.setState(state => ({
			keys: {
				...state.keys,
				[type]: value
			}
		}));
	};

	handleArrowClick(direction, type, event) {
		this.handleContentClick(event);
		this.setState({
			stop: true
		});

		if (direction === -1) {
			return this.decreaseIndex(type);
		}
		return this.increaseIndex(type);
	}

	decreaseIndex(type) {
		this.setState(state => {
			let currentKey = state.keys[type] - 1;
			const media = this.props.medias[type];
			if (currentKey < 0) {
				currentKey = media.length - 1;
			}
			return {
				keys: {
					...state.keys,
					[type]: currentKey
				}
			};
		});
	}

	increaseIndex(type) {
		this.setState(state => {
			let currentKey = state.keys[type] + 1;
			const media = this.props.medias[type];
			if (currentKey > media.length - 1) {
				currentKey = 0;
			}
			return {
				keys: {
					...state.keys,
					[type]: currentKey
				}
			};
		});
	}

	render() {
		const { medias, classes } = this.props;
		const mediaList = Object.keys(medias);
		return (
			<div className={classes.rootWrapper}>
				<Controls onClick={this.handleOpen} medias={medias} />
				{mediaList.map((type, index) => {
					const list = medias[type];
					console.log("this.state.keys[type]", this.state.keys, type);
					if (list.length < 1) {
						return null;
					}
					return (
						<div
							key={index}
							className={classNames(
								classes.root,
								this.state[type] ? classes.opened : classes.closed
							)}
							onClick={() => this.handleOpen(type)}
						>
							<div
								className={classes.wrapper}
								open={this.state[type]}
								onClick={this.handleContentClick}
							>
								<Tabs
									// defaultActiveKey="0"
									activeKey={`${this.state.keys[type]}`}
									tabBarPosition="bottom"
									renderTabBar={() => <TabBar className={classes.appBar} />}
									renderTabContent={() => (
										<TabContent
											className={classNames(
												classes.tabContent,
												type === "iframe" ? "height" : ""
											)}
										/>
									)}
									className={classNames(classes.tabs)}
								>
									{list.map((media, index2) => {
										if (typeof media !== "object") {
											media = {
												src: media,
												title: media
											};
										}
										if (type === "image") {
											return (
												<TabPane
													className={classes.tab}
													tab={index2}
													key={String(index2)}
												>
													<img
														className={classes.strech}
														src={`.${media.src}`}
														title={media.title}
													/>
												</TabPane>
											);
										}
										if (type === "iframe") {
											const vimeoRegexp = /vimeo+/iu;
											if (vimeoRegexp.test(media.src)) {
												const tmpArr = media.src.split("/");
												media.src = `https://player.vimeo.com/video/${
													tmpArr[tmpArr.length - 1]
												}`;
											}
											return (
												<TabPane
													className={classes.tab}
													tab={index2}
													key={String(index2)}
												>
													<iframe
														src={media.src}
														width="100%"
														height="100%"
														frameBorder="0"
														allow="fullscreen"
														allowFullScreen
													/>
												</TabPane>
											);
										}
										if (type === "video") {
											return (
												<TabPane
													className={classNames(classes.tab, "video")}
													tab={index2}
													key={String(index2)}
												>
													<Player
														className={classNames(classes.strech, "height")}
														src={`.${media.src}`}
														title={media.title}
														stop={this.state.stop}
													/>
												</TabPane>
											);
										}
										return null;
									})}
								</Tabs>
							</div>
							{list.length > 1 && (
								<>
									<Dots
										count={list.length}
										index={correction(this.state.keys[type], list.length)}
										className={classNames(classes.dots)}
										type={type}
										onDotClick={this.handleChange}
										onClick={this.handleContentClick}
									/>
									<Fab
										className={classNames(classes.arrow, classes.arrowLeft)}
										onClick={e => this.handleArrowClick(-1, type, e)}
									>
										<LeftArrowIcon className={classes.arrowIcon} />
									</Fab>
									<Fab
										className={classNames(classes.arrow, classes.arrowRight)}
										onClick={e => this.handleArrowClick(1, type, e)}
									>
										<RightArrowIcon className={classes.arrowIcon} />
									</Fab>
								</>
							)}
							<IconButton
								aria-label="Close"
								className={classNames(classes.closeBtn)}
								onClick={e => this.handleOpen(type, e)}
							>
								<CloseIcon fontSize="large" />
							</IconButton>
						</div>
					);
				})}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	medias: state.carousel[state.helpers.building]
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

Carousel.propTypes = {
	classes: PropTypes.object.isRequired
};

Carousel.defaultProps = {};

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Carousel)
);
