export const popup = theme => ({
	wrapper: {
		position: "absolute",
		width: theme.spacing.unit * 30,
		height: theme.spacing.unit * 8,
		backgroundColor: "#fff",
		zIndex: 99999
	},
	left: {
		color: "#fff",
		height: "100%"
	},
	right: {
		padding: `0 ${theme.spacing.unit * 2}px`
	},
	lot: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		textAlign: "center",
		height: "100%",
		justifyContent: "center"
	},
	value: {
		display: "flex",
		width: "100%",
		justifyContent: "left"
	},
	valueBold: {
		fontWeight: 700
	}
});
