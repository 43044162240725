import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core";

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import TabBar from "rc-tabs/lib/TabBar";

import { main as styles } from "./styles";
import { Canvas, Logo } from "../../../components";
import "rc-tabs/assets/index.css";

class Main extends React.Component {
	render() {
		const { classes, poi } = this.props;

		return (
			<main className={classes.mainWrapper}>
				<div className={classes.tabs}>
					{!poi.axono || (poi.axono.length > 0 && poi.axono[0] === "") ? (
						<div>
							<Canvas
								helperChooser="lci"
								poi={poi.lot}
								classes={{
									canvasPOI: classes.canvasFullHeight
								}}
							/>
							<Logo />
						</div>
					) : (
						<Tabs
							defaultActiveKey="1"
							tabBarPosition="bottom"
							renderTabBar={() => <TabBar className={classes.appBar} />}
							renderTabContent={() => <TabContent />}
							className={classes.tabs}
						>
							<TabPane tab="Situation" key="1">
								<Canvas
									helperChooser="lci"
									poi={poi.lot}
									className={classes.canvas}
								/>
								<Logo />
							</TabPane>
							{poi.axono.map((axono, index) =>
								axono.match(/\.(jpg|jpeg|png)/iu) ? (
									<TabPane
										className={classes.tab}
										tab="AXONOMETRIE"
										key={index + 2}
									>
										<img
											title={`AXONOMETRIE ${index + 1}`}
											className={classes.iframe}
											src={axono}
											frameBorder="0"
										/>
									</TabPane>
								) : (
									<TabPane className={classes.tab} tab="3D" key={index + 2}>
										<iframe
											title="3D"
											className={classes.iframe}
											src={axono}
											frameBorder="0"
										/>
									</TabPane>
								)
							)}
						</Tabs>
					)}
				</div>
			</main>
		);
	}
}

const mapStateToProps = state => ({
	pois: state.pois
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

Main.propTypes = {
	classes: PropTypes.object.isRequired,
	poi: PropTypes.object.isRequired
};

export default withRouter(
	withStyles(styles)(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(Main)
	)
);
