import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { windowSizeChange, setRoute } from "../../store/helpers";

import { Routes } from "../../components";

class App extends Component {
	componentDidMount() {
		this.props.windowSizeChange(this.props.window.width);
		window.addEventListener("resize", this.props.windowSizeChange);
	}

	componentDidUpdate() {
		this.props.setRoute(
			this.props.history.location.pathname.replace(/\//u, "")
		);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.props.windowSizeChange);
	}

	render() {
		return <Routes />;
	}
}

const mapStateToProps = state => ({
	...state.helpers
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			windowSizeChange,
			setRoute
		},
		dispatch
	);

App.propTypes = {
	history: PropTypes.object.isRequired,
	setRoute: PropTypes.func.isRequired,
	window: PropTypes.object.isRequired,
	windowSizeChange: PropTypes.func.isRequired
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(App)
);
