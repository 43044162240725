import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import styles from "./styles";
import { Sidebar, Main, Header } from "./partials";
import { Wrapper } from "../index";

class Poi extends React.Component {
	render() {
		const { classes, match, pois } = this.props;
		const { poi } = match.params;
		const item = pois[poi];
		return (
			<Wrapper>
				<Header className={classes.header} />
				<div className={classes.wrapper}>
					<Main className={classes.main} poi={item} />
					<Sidebar className={classes.sidebar} poi={item} />
				</div>
			</Wrapper>
		);
	}
}

const mapStateToProps = state => ({
	pois: state.pois[state.helpers.building]
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

Poi.propTypes = {
	classes: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	pois: PropTypes.object.isRequired
};

export default withRouter(
	withStyles(styles)(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(Poi)
	)
);
