const setEvents = () => {};

const findLci = that => {
	const { stage } = that.state;
	const { poi, frame } = that.props;
	let resultFrame = frame;

	that.props.pois
		// filter((item) => ids.indexOf(Object.keys(item)[0]) > -1).
		.filter(item => {
			const [name] = Object.keys(item);
			return name === poi;
		})
		.forEach(poiItem => {
			const [name] = Object.keys(poiItem);
			const values = poiItem[name].coordinates;
			const result = values.filter(value => value._column === that.props.frame);
			if (result.length === 0) {
				resultFrame = values[0]._column + 0;
			}
		});
	return resultFrame;
};

const update360 = (dir, that) => {
	const { canvas, stage } = that.state;
	const { shapes, framesCount, setFrame, ids, poi, lci } = that.props;

	let frame = Math.abs(dir) > 1 ? dir : that.props.frame + dir;

	if (frame < 0) {
		frame = framesCount - 1;
	} else if (frame > framesCount - 1) {
		frame = 0;
	}

	setFrame(frame);

	that.resetStage("all");

	that.props.pois
		.filter(item => ids.indexOf(Object.keys(item)[0]) > -1)
		.forEach(poiItem => {
			const [name] = Object.keys(poiItem);
			const values = poiItem[name].coordinates;
			const result = values.filter(value => value._column === that.props.frame);

			shapes[name].children[0].x = -100;
			shapes[name].children[0].y = -100;
			shapes[name].children[1].x = -100;
			shapes[name].children[1].y = -100;

			if (result.length > 0 && name === poi) {
				const x = canvas.width * result[0]._x;
				const y = canvas.height * result[0]._y;
				const lciPoint = lci[poi];
				lciPoint.scaleX = "0.6";
				lciPoint.scaleY = "0.6";
				lciPoint.x = x - 108;
				lciPoint.y = y;
				stage.addChild(lciPoint);
			}
		});
	stage.update();
};

export default {
	setEvents,
	update360,
	findLci
};
