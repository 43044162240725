import PropTypes from "prop-types";
import React from "react";
import styles from "./styles";

import {
	Drawer,
	AppBar,
	Toolbar,
	Typography,
	Fab,
	withStyles
} from "@material-ui/core";

import { ReactComponent as ShareIcon } from "../../assets/images/icons/share.svg";
import { ReactComponent as ExportIcon } from "../../assets/images/icons/export.svg";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Results from "./Results";

import { Share, Carousel } from "../index";

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.anchorEl = React.createRef();
	}

	state = {
		anchorEl: null,
		element: "button",
		open: false
	};

	setButtonRef = element => {
		this.anchorEl = element;
		this.setState(prevState => ({
			...prevState,
			element
		}));
	};

	handleShareOpen = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleShareClose = () => {
		this.setState(prevState => ({
			...prevState,
			anchorEl: null
		}));
	};

	render() {
		const { classes, sidebar, data, count } = this.props;
		const { opened } = sidebar;
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);

		return (
			<>
				<Drawer
					anchor="right"
					classes={{
						paper: classes.drawerPaper
					}}
					open={opened}
					variant="persistent"
				>
					<div className={classes.toolbar} />
					<div className={classes.wrapper}>
						<AppBar
							classes={{
								root: classes.resultHeaderAppBar
							}}
							color="inherit"
							position="static"
						>
							<Toolbar
								classes={{
									root: classes.resultHeaderToolbar
								}}
							>
								<Typography
									classes={{
										root: classes.resultHeaderTitle
									}}
									variant="h3"
								>
									<span className={classes.resultHeaderCount}>{count}</span>
									{"résultats"}
								</Typography>
								<div className={classes.resultHeaderActions}>
									<Fab
										className={classes.resultHeaderButton}
										download="Data.csv"
										href="./data/data.csv"
										variant="extended"
									>
										<ExportIcon className={classes.resultHeaderIcon} />
										<span>{"export.csv"}</span>
									</Fab>
									<Fab
										aria-haspopup="true"
										aria-owns={this.state.open ? "simple-popper" : null}
										buttonRef={this.setButtonRef}
										className={classes.resultHeaderButton}
										onClick={this.handleShareOpen}
										variant="extended"
									>
										<ShareIcon className={classes.resultHeaderIcon} />
										<span>{"partage"}</span>
									</Fab>
									<Share
										element={this.anchorEl}
										onClose={this.handleShareClose}
										open={open}
										url={window.location.href}
									/>
								</div>
							</Toolbar>
						</AppBar>
						<Results classes={classes} data={data} />
					</div>
				</Drawer>
				{opened ? <Carousel /> : null}
			</>
		);
	}
}

const mapStateToProps = state => ({
	...state.filtered[state.helpers.building],
	...state.helpers
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	count: PropTypes.number.isRequired,
	data: PropTypes.object.isRequired,
	sidebar: PropTypes.object.isRequired
};

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Sidebar)
);
