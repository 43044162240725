import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";

const styles = {
	dots: {
		position: "relative",
		padding: "20px 0 28px",
		width: "100%",
		display: "flex",
		justifyContent: "center"
	},
	dotOuter: {
		width: 24,
		height: 24,
		float: "left",
		position: "initial",
		marginRight: 8,
		cursor: "pointer"
	},
	dot: {
		marginRight: 8,
		width: 24,
		height: 24,
		backgroundColor: "rgba(255,255,255,0)",
		transition: "all 200ms cubic-bezier(0.4, 0.0, 0.2, 1)",
		borderRadius: "100%",
		border: "2px solid #fff"
	},
	selected: {
		backgroundColor: "rgba(255,255,255,1)"
	}
};

class Dots extends Component {
	constructor(props) {
		super(props);
		this.state = {
			previousIndex: props.index || 0
		};
	}

	componentWillReceiveProps({ index }) {
		if (index !== this.props.index) {
			this.setState({ previousIndex: this.props.index });
			this.timeout = setTimeout(() => {
				this.timeout = null;
				this.setState({ previousIndex: index });
			}, 450);
		}
	}

	componentWillUnmount() {
		if (this.timeout !== null) {
			clearTimeout(this.timeout);
		}
	}

	handleDotClick = index => {
		if (this.props.onDotClick !== null) {
			this.props.onDotClick(this.props.type, index);
		}
	};

	render() {
		const { classes, count, index, style = {}, ...other } = this.props;
		const { previousIndex } = this.state;
		return (
			<div
				style={{
					...style,
					width: "100%",
					display: "flex",
					alignItems: "center"
				}}
				{...other}
			>
				<div className={classes.dots}>
					{[...Array(count).keys()].map(i => {
						const selected =
							i >= Math.min(previousIndex, index) &&
							i <= Math.max(previousIndex, index);
						return (
							<div
								key={i}
								className={classes.dotOuter}
								onClick={event => this.handleDotClick(i, event)}
							>
								<div
									className={classNames(
										classes.dot,
										selected ? classes.selected : ""
									)}
								/>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

Dots.propTypes = {
	count: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired,
	onDotClick: PropTypes.func,
	style: PropTypes.object
};

export default withStyles(styles)(Dots);
