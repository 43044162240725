export default theme => ({
	root: {
		width: "auto",
		height: "100%",
		alignContent: "center",
		alignItems: "center",
		textAlign: "center",
		display: "flex",
		justifyContent: "center",
		overflow: "hidden",
		"&.rootLCI": {
			height: "calc(100% + 50px)",
			[theme.breakpoints.down("sm")]: {
				height: "auto",
				width: "100vw"
			}
		}
	},
	canvas: {
		height: "100%",
		width: "auto"
	},
	arrowsContainer: {
		position: "absolute",
		bottom: theme.spacing.unit * 2,
		left: "calc(50% - 46px)"
	},
	arrows: {
		minWidth: "auto",
		padding: 0,
		marginRight: theme.spacing.unit
	},
	arrowLeft: {
		marginRight: theme.spacing.unit * 2
	},
	arrowRight: {
		marginRight: theme.spacing.unit * 2
	},
	arrowIcon: {
		width: "2.5rem",
		height: "2.5rem",
		fill: theme.palette.primary.contrastText
	},
	eye: {
		padding: theme.spacing.unit / 2
	},
	[theme.breakpoints.down("sm")]: {
		arrowsContainer: {
			left: "calc(50% - 38px)"
		},
		arrowLeft: {},
		arrowRight: {}
	}
});
