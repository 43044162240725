import { SET_SETTINGS } from "./constants";
import initialState from "./initialState";

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_SETTINGS:
			return {
				...initialState
			};

		default:
			return state;
	}
};
