export default theme => ({
	appBar: {
		display: "flex",
		backgroundColor: theme.palette.background.default,
		transition: theme.transitions.create(["width", "margin"], {
			duration: theme.transitions.duration.leavingScreen,
			easing: theme.transitions.easing.sharp
		}),
		zIndex: theme.zIndex.drawer + 1
	},
	filters: {},
	advancedSearch: {
		position: "absolute",
		right: 0,
		marginRight: theme.spacing.unit * 5,
		display: "flex",
		alignItems: "center"
	},
	btnOpenSidebar: {
		transform: "rotate(0deg)",
		transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
	},
	advancedSearchOpened: {
		transform: "rotate(45deg)"
		// backgroundColor: theme.palette.primary.dark
	},
	filter: {
		display: "inline-flex",
		alignItems: "center",
		marginRight: theme.spacing.unit * 3,
		"&:last-child": {
			margin: 0
		}
	},
	root: {
		display: "flex"
	},
	title: {
		marginRight: theme.spacing.unit * 3
	},
	toolbar: {
		...theme.mixins.toolbar,
		alignItems: "center"
	},
	btn: {
		width: 30,
		height: 30,
		"@media (min-width:960px) and (max-width:1440px)": {
			width: 24,
			height: 24
		},
		"& svg": {
			"@media (min-width:960px) and (max-width:1440px)": {
				width: 18,
				height: 18
			}
		}
	},
	[theme.breakpoints.down("md")]: {
		filter: {
			marginRight: theme.spacing.unit
		},
		toolbar: {
			padding: theme.spacing.unit
		},
		advancedSearch: {
			marginRight: theme.spacing.unit
		},
		title: {
			marginRight: theme.spacing.unit
		}
	}
});
