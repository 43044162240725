import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Helmet from "react-helmet";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import db from "../../data/db.json";
import logo from "../../assets/images/vectuel.svg";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setRoute } from "../../store/helpers";

import { title } from "../../config";

const SITE_URL =
	process.env.NODE_ENV === "development"
		? "http://localhost:3000"
		: "http://localhost:5000";

const defaultTitle = `Webimmo | ${title}`;
const defaultDescription = "Webimmo description";
const defaultImage = `${SITE_URL}${logo}`;
const defaultSep = " | ";

class Wrapper extends Component {
	// componentDidMount() {
	// const { hash } = window.location;
	// const { state } = this.props.history.location;
	// const building = hash.replace(/#\//u, "") ? hash.replace(/#\//u, "").split("/")[0] : Object.keys(db)[0];
	// console.log("building", building);
	// }

	// componentDidUpdate (prevProps) {
	// will be true
	// const locationChanged = this.props.location !== prevProps.location;
	// console.log("locationChanged", locationChanged);
	// }

	getMetaTags(
		{
			title,
			description,
			image,
			contentType,
			noCrawl,
			published,
			updated,
			category,
			tags
		},
		pathname
	) {
		const theTitle = title
			? (defaultTitle + defaultSep + title).substring(0, 60)
			: defaultTitle;
		const theDescription = description
			? description.substring(0, 155)
			: defaultDescription;
		const theImage = image ? `${SITE_URL}${image}` : defaultImage;

		const metaTags = [
			{ itemprop: "name", content: theTitle },
			{ itemprop: "description", content: theDescription },
			{ itemprop: "image", content: theImage },
			{ name: "description", content: theDescription },
			{ name: "twitter:card", content: "summary_large_image" },
			{ name: "twitter:title", content: theTitle },
			{ name: "twitter:description", content: theDescription },
			{ name: "twitter:image:src", content: theImage },
			{ property: "og:title", content: theTitle },
			{ property: "og:type", content: contentType || "website" },
			{ property: "og:url", content: SITE_URL + pathname },
			{ property: "og:image", content: theImage },
			{ property: "og:description", content: theDescription },
			{ property: "og:site_name", content: defaultTitle }
		];

		if (noCrawl) {
			metaTags.push({ name: "robots", content: "noindex, nofollow" });
		}

		if (published) {
			metaTags.push({ name: "article:published_time", content: published });
		}
		if (updated) {
			metaTags.push({ name: "article:modified_time", content: updated });
		}
		if (category) {
			metaTags.push({ name: "article:section", content: category });
		}
		if (tags) {
			metaTags.push({ name: "article:tag", content: tags });
		}

		return metaTags;
	}

	render() {
		const { children, location, title, classes, ...rest } = this.props;

		return (
			<>
				<Helmet
					htmlAttributes={{
						lang: "en",
						// itemscope: undefined,
						itemtype: `http://schema.org/${rest.schema || "WebPage"}`
					}}
					title={title ? title + defaultSep + defaultTitle : defaultTitle}
					link={[
						{
							rel: "canonical",
							href: SITE_URL + location.pathname
						}
					]}
					meta={this.getMetaTags(rest, location.pathname)}
				/>
				<div className={classNames(classes.root, rest.className)}>
					{children}
				</div>
			</>
		);
	}
}

Wrapper.propTypes = {
	children: PropTypes.any.isRequired,
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	title: PropTypes.string
};

Wrapper.defaultProps = {
	title: null
};

const mapStateToProps = state => ({
	...state.helpers
});

const mapDispatchToProps = dispatch =>
	bindActionCreators({ setRoute }, dispatch);

export default withRouter(
	withStyles(styles)(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(Wrapper)
	)
);
