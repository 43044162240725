import { SET_FILTER, RESET_FILTERES, SET_FILTERS } from "./constants";
import { setFiltered } from "../filtered";

export const setFilters = routeName => (dispatch, getState) =>
	new Promise(resolve => {
		const { building } = getState().helpers;

		dispatch({
			type: SET_FILTERS,
			routeName,
			building
		});
		dispatch(setFiltered());
		resolve(routeName);
	});

export const reset = type => (dispatch, getState) =>
	new Promise(resolve => {
		const { building } = getState().helpers;
		dispatch({
			type: RESET_FILTERES,
			building
		});
		dispatch(setFiltered());
		resolve(type);
	});

export const setType = type => (dispatch, getState) =>
	new Promise(resolve => {
		const { building } = getState().helpers;
		dispatch({
			type: SET_FILTER,
			value: type,
			filterName: "type",
			filterType: "checkbox",
			building
		});
		dispatch(setFiltered());
		resolve(type);
	});
export const setFloor = (floor, type) => (dispatch, getState) =>
	new Promise(resolve => {
		const { building } = getState().helpers;
		dispatch({
			type: SET_FILTER,
			value: floor,
			filterName: "floor",
			filterType: type,
			building
		});
		dispatch(setFiltered());
		resolve(floor);
	});
export const setSurface = surface => (dispatch, getState) =>
	new Promise(resolve => {
		const { building } = getState().helpers;
		dispatch({
			type: SET_FILTER,
			value: surface,
			filterName: "surface",
			filterType: "range",
			building
		});
		dispatch(setFiltered());
		resolve(surface);
	});
export const setPrice = price => (dispatch, getState) =>
	new Promise(resolve => {
		const { building } = getState().helpers;
		dispatch({
			type: SET_FILTER,
			value: price,
			filterName: "price",
			filterType: "range",
			building
		});
		dispatch(setFiltered());
		resolve(price);
	});
export const setBenefit = benefit => (dispatch, getState) =>
	new Promise(resolve => {
		const { building } = getState().helpers;
		dispatch({
			type: SET_FILTER,
			value: benefit,
			filterName: "benefits",
			filterType: "checkbox",
			building
		});
		dispatch(setFiltered());
		resolve(benefit);
	});
