import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { Filter } from "../index";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { filters } from "../../store";

class Filters extends React.Component {
	// state = {
	// 	lang: "en"
	// };

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		const { classes, list } = this.props;

		return (
			<div className={classNames(classes.filters)}>
				{Object.values(list).map(item => {
					if (item.name === "types" || item.name === "floors") {
						return (
							<div className={classNames(classes.filter)} key={item.name}>
								<Filter item={item} />
							</div>
						);
					}
					return null;
				})}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	filtersList: {
		...state.filters[state.helpers.building]
	},
	list: {
		...state.settings[state.helpers.building].filters
	}
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			type: filters.setType,
			floor: filters.setFloor,
			benefit: filters.setBenefit,
			price: filters.setPrice,
			surface: filters.setSurface
		},
		dispatch
	);

Filters.propTypes = {
	classes: PropTypes.object.isRequired
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Filters);
