export default theme => ({
	wrapper: {
		display: "flex",
		flexDirection: "row"
	},
	header: {
		width: "100vw"
	},
	main: {
		width: "75vw",
		height: "100%"
	},
	sidebar: {
		width: "25vw",
		height: "100%"
	},
	[theme.breakpoints.down("sm")]: {
		wrapper: {
			display: "flex",
			flexDirection: "column-reverse"
		},
		header: {
			width: "100vw"
		},
		main: {
			width: "100vw",
			height: "auto"
		},
		sidebar: {
			width: "100vw",
			height: "auto"
		}
	}
});
