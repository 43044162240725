import {
	SET_CANVAS,
	SET_CANVAS_IMAGES,
	TOGGLE_LIGHTS,
	SET_FRAME
} from "./constants";

import initialState from "./initialState";

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_CANVAS:
			return {
				...initialState
			};
		case SET_CANVAS_IMAGES:
			return {
				...state,
				[action.building]: {
					...state[action.building],
					floorsImages: action.images
				}
			};
		case TOGGLE_LIGHTS:
			return {
				...state,
				[action.building]: {
					...state[action.building],
					lights: !state[action.building].lights
				}
			};
		case SET_FRAME:
			return {
				...state,
				[action.building]: {
					...state[action.building],
					frame: action.frame
				}
			};

		default:
			return state;
	}
};
