export default theme => ({
	rootWrapper: {
		position: "fixed",
		width: "20vw",
		height: "auto",
		right: 0,
		bottom: 0,
		zIndex: 1202,
		[theme.breakpoints.down("sm")]: {
			width: "100vw"
		}
	},
	root: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: "100vw",
		height: "100vh",
		zIndex: 1202,
		background: "rgba(0,0,0,.7)",
		alignItems: "center",
		justifyCenter: "center",
		"& *": {
			scrollbarWidth: "none"
		}
	},
	wrapper: {
		width: "65vw",
		height: "70vh",
		margin: "auto",
		overflow: "hidden",
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			width: "100vw"
		}
	},
	tabs: {},
	opened: {
		display: "flex"
	},
	closed: {
		display: "none"
	},
	arrow: {
		width: 100,
		height: 100,
		position: "absolute",
		background: "none",
		boxShadow: "none",
		"&:hover, &:focus": {
			background: "none"
		},
		[theme.breakpoints.down("sm")]: {
			bottom: 0
		}
	},
	arrowLeft: {
		left: "10vw"
	},
	arrowRight: {
		right: "10vw"
	},
	arrowIcon: {
		color: "#fff",
		width: 48,
		height: 48
	},
	dots: {
		margin: "0 auto",
		position: "absolute",
		bottom: "7vh",
		[theme.breakpoints.down("sm")]: {
			display: "none !important"
		}
	},
	closeBtn: {
		color: "#fff",
		position: "absolute",
		top: "10vh",
		right: "10vw",
		[theme.breakpoints.down("sm")]: {
			top: 0,
			right: 0
		}
	},
	appBar: {
		display: "none"
	},
	strech: {
		width: "100%",
		height: "auto",
		"&.height": {
			height: "100%"
		}
	},
	tabContent: {
		alignItems: "center"
	},
	"@global": {
		".rc-tabs-bottom": {
			border: 0
		},
		".rc-tabs": {
			display: "flex",
			width: "100%",
			height: "100%"
		},
		".rc-tabs-tabpane": {
			display: "flex",
			alignItems: "center"
		},
		".rc-tabs-content": {
			alignItems: "center",
			// backgroundColor: "#fff",
			"&.height .rc-tabs-tabpane": {
				height: "100%"
			}
		},
		".rc-tabs-bottom .rc-tabs-content-animated .rc-tabs-tabpane:not(.video)": {
			backgroundColor: "#fff"
		}
	}
});

export const controls = theme => ({
	root: {
		display: "flex",
		backgroundColor: "#fff",
		boxShadow: "0px -8px 8px -4px rgba(0,0,0,0.2)",
		padding: 8,
		alignItems: "center"
	},
	title: {
		textTransform: "uppercase",
		margin: "0 8px 0 0",
		fontSize: "1.3rem"
	},
	btn: {
		marginRight: 8,
		height: "3rem",
		width: "3rem",
		minHeight: "auto",
		"&:last-child": {
			marginRight: 0
		}
	},
	btnIcon: {
		fill: "#fff",
		color: "#fff",
		width: "55%",
		height: "55%"
	},
	actions: {
		width: "100%",
		display: "flex",
		justifyContent: "space-evenly"
	}
});
