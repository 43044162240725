import PropTypes from "prop-types";
import React from "react";
import styles from "./styles";

import {
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Typography,
	withStyles
} from "@material-ui/core";

import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Card } from "../index";

class Results extends React.Component {
	render() {
		const { classes, data } = this.props;
		return (
			<div>
				{Object.keys(data).map(name => {
					const typesData = data[name];

					if (Object.keys(typesData).length === 0) {
						return null;
					}

					return (
						<ExpansionPanel
							classes={{
								root: classes.panelRoot
							}}
							key={name}
						>
							<ExpansionPanelSummary
								classes={{
									root: classes.panelSummaryRoot,
									expanded: classes.expanded
								}}
								expandIcon={
									<ExpandMoreIcon
										classes={{
											root: classes.panelSummaryIcon
										}}
									/>
								}
							>
								<Typography className={classes.panelSummaryHeading}>
									{`${name.replace(/(?!\/)\D+/gimu, "")} pièces (${
										Object.keys(typesData).length
									})`}
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails
								classes={{
									root: classes.panelDetails
								}}
							>
								{Object.keys(typesData).map(item => (
									<Card
										className={classes.card}
										item={typesData[item]}
										key={`${typesData[item].lot}`}
									/>
								))}
							</ExpansionPanelDetails>
						</ExpansionPanel>
					);
				})}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state.filtered[state.helpers.building]
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

Results.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired
	// settings: PropTypes.object
};

Results.defaultProps = {
	// settings: null
};

export default withStyles(styles)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Results)
);
