import PropTypes from "prop-types";
import React from "react";
import { item as styles } from "./styles";

import {
	Typography,

	withStyles
} from "@material-ui/core";

class Item extends React.Component {

	render () {
		const { classes, icon, label } = this.props;
		const Icon = icon;

		return (
			<div className={classes.wrapper}>
				<Icon className={classes.icon} />
				<Typography className={classes.text}>
					{label}
				</Typography>
			</div>
		);
	}

}

Item.propTypes = {
	classes: PropTypes.object.isRequired,
	icon: PropTypes.any.isRequired,
	label: PropTypes.any.isRequired
};

export default withStyles(styles)(Item);
