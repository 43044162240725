import React from "react";
import autoPlay from "react-swipeable-views-utils/lib/autoPlay";
import virtualize from "react-swipeable-views-utils/lib/virtualize";
import bindKeyboard from "react-swipeable-views-utils/lib/bindKeyboard";
import SwipeableViews from "react-swipeable-views";

const correction = (a, n) => ((a % n) + n) % n;

const VirtualizeSwipeViews = bindKeyboard(virtualize(SwipeableViews));
const VirtualizeAutoPlaySwipeViews = autoPlay(VirtualizeSwipeViews);

const carouselSlideRenderer = children => ({ index, key }) =>
	React.cloneElement(children[correction(index, children.length)], { key });

export default function View({ children, autoplay, ...other }) {
	const slideRenderer = carouselSlideRenderer(children);
	return autoplay ? (
		<VirtualizeAutoPlaySwipeViews {...other} slideRenderer={slideRenderer} />
	) : (
		<VirtualizeSwipeViews {...other} slideRenderer={slideRenderer} />
	);
}
