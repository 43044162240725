import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../utils/ThemeProvider";

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
	position: "absolute",
	width: "100%",
	height: theme.spacing.unit - 2,
	transform: "translate(0%, -50%)",
	borderRadius: 7,
	cursor: "pointer"
};

const railInnerStyle = {
	position: "absolute",
	width: "100%",
	height: theme.spacing.unit - 2,
	transform: "translate(0%, -50%)",
	borderRadius: 7,
	pointerEvents: "none",
	backgroundColor: "rgb(155,155,155)"
};

const SliderRail = ({ getRailProps }) => (
	<>
		<div
			style={railOuterStyle}
			{...getRailProps()}
		/>
		<div style={railInnerStyle} />
	</>
);

SliderRail.propTypes = {
	getRailProps: PropTypes.func.isRequired
};

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
const Handle = ({
	domain: [
		min,
		max
	],
	handle: {
		id,
		value,
		percent
	},
	disabled,
	getHandleProps
}) => (<>
	<div
		style={{
			left: `${percent}%`,
			position: "absolute",
			transform: "translate(-50%, -50%)",
			WebkitTapHighlightColor: "rgba(0,0,0,0)",
			zIndex: 5,
			width: 28,
			height: 42,
			cursor: "pointer",
			// border: "1px solid white",
			backgroundColor: "none"
		}}
		{...getHandleProps(id)}
	/>
	<div
		role="slider"
		aria-valuemin={min}
		aria-valuemax={max}
		aria-valuenow={value}
		style={{
			left: `${percent}%`,
			position: "absolute",
			transform: "translate(-50%, -50%)",
			zIndex: 2,
			borderRadius: "50%",
			boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
			backgroundColor: disabled
				? theme.palette.primary.contrastText
				: theme.palette.primary.main,
			width: theme.spacing.unit * 2.5,
			height: theme.spacing.unit * 2.5
		}}
	/>
       </>);

Handle.propTypes = {
	disabled: PropTypes.bool,
	domain: PropTypes.array.isRequired,
	getHandleProps: PropTypes.func.isRequired,
	handle: PropTypes.shape({ id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired }).isRequired
};

Handle.defaultProps = {
	disabled: false
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
const Track = ({ source, target, getTrackProps, disabled }) => (<div
	style={{
		position: "absolute",
		transform: "translate(0%, -50%)",
		zIndex: 1,
		backgroundColor: disabled
			? theme.palette.primary.contrastText
			: theme.palette.primary.light,
		height: theme.spacing.unit - 2,
		borderRadius: 7,
		cursor: "pointer",
		left: `${source.percent}%`,
		width: `${target.percent - source.percent}%`
	}}
	{...getTrackProps()}
/>);

Track.propTypes = {
	disabled: PropTypes.bool,
	getTrackProps: PropTypes.func.isRequired,
	source: PropTypes.shape({ id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired }).isRequired,
	target: PropTypes.shape({ id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired }).isRequired
};

Track.defaultProps = {
	disabled: false
};

export {
	SliderRail,
	Track,
	Handle
};
