import { SET_FILTER, RESET_FILTERES, SET_FILTERS } from "./constants";
import initialState from "./initialState";
import { reset } from "./actions";

export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
	let checked = 0;
	const resetState = {
		...JSON.parse(JSON.stringify(initialState))
	};
	switch (action.type) {
		case SET_FILTERS:
			return {
				...initialState
			};
		case RESET_FILTERES:
			console.log("reset", resetState);
			return {
				...resetState
			};
		case SET_FILTER:
			if (action.filterType === "checkbox") {
				if (
					action.filterName === "floor" &&
					typeof state[action.building][action.filterName] === "string"
				) {
					state[action.building][action.filterName] = [];
				}

				checked = state[action.building][action.filterName].indexOf(
					action.value
				);
				if (checked > -1) {
					state[action.building][action.filterName].splice(checked, 1);
				} else {
					state[action.building][action.filterName].push(action.value);
				}
			} else {
				state[action.building][action.filterName] = action.value;
			}
			return {
				...state
			};

		default:
			return state;
	}
};
