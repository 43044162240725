export { default } from "./reducers";
export { SET_FILTER, RESET_FILTERES, SET_FILTERS } from "./constants";
export {
	setType,
	setFloor,
	setSurface,
	setPrice,
	setBenefit,
	reset,
	setFilters
} from "./actions";
