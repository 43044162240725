// import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import styles from "./styles";

import {
	Popover,
	withStyles
} from "@material-ui/core";

import {
	FacebookShareButton,
	TwitterShareButton,
	EmailShareButton,
	FacebookIcon,
	TwitterIcon,
	EmailIcon
} from "react-share";


class Share extends Component {

	handleClose = () => {
		const { onClose } = this.props;
		return onClose();
	}

	render () {
		const { classes, open, element, url } = this.props;

		return (
			<Popover
				anchorEl={element}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				id="simple-popper"
				onClose={this.handleClose}
				open={open}
				PaperProps={{
					classes: {
						root: classes.root
					}
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				<EmailShareButton
					className={classes.button}
					url={url}
				>
					<EmailIcon
						round
						size={30}
					/>
				</EmailShareButton>
				<FacebookShareButton
					className={classes.button}
					url={url}
				>
					<FacebookIcon
						round
						size={30}
					/>
				</FacebookShareButton>
				<TwitterShareButton
					className={classes.button}
					url={url}
				>
					<TwitterIcon
						round
						size={30}
					/>
				</TwitterShareButton>
			</Popover>
		);
	}

}

Share.propTypes = {
	classes: PropTypes.object.isRequired,
	element: PropTypes.any,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	url: PropTypes.string.isRequired
};

Share.defaultProps = {
	element: null
};

export default withStyles(styles)(Share);
