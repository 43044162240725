import { SET_FILTERED, SET_FILTERED_ON_ROUTE_CHANGE } from "./constants";

export const setFilteredOnRouteChange = routeName => dispatch =>
	new Promise(resolve => {
		dispatch({
			type: SET_FILTERED_ON_ROUTE_CHANGE,
			routeName
		});
		resolve(routeName);
	});

export const getFiltered = () => (dispatch, getState) =>
	new Promise(resolve => {
		const { filtered, building } = getState();
		resolve(filtered);
	});

export const setFiltered = () => (dispatch, getState) =>
	new Promise(resolve => {
		const { filtered, filters, pois, settings, helpers } = getState();
		dispatch({
			type: SET_FILTERED,
			filters: filters[helpers.building],
			pois: pois[helpers.building],
			types: settings[helpers.building].types,
			building: helpers.building
		});
		resolve(filtered);
	});
