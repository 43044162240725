import PropTypes from "prop-types";
import React from "react";
import { content as styles } from "./styles";

import { ReactComponent as SurfaceIcon } from "../../assets/images/icons/surface.svg";
import { ReactComponent as SituationIcon } from "../../assets/images/icons/situation.svg";
import { ReactComponent as ExpositionIcon } from "../../assets/images/icons/exposition.svg";
import { ReactComponent as AnnexesIcon } from "../../assets/images/icons/annexes.svg";
import { ReactComponent as StatusIcon } from "../../assets/images/icons/status.svg";

import { withStyles } from "@material-ui/core";

import Item from "./Item";

class Content extends React.Component {
	render() {
		const { item, classes } = this.props;
		const {
			loggia,
			garden,
			balcony,
			balcony_2,
			terrace,
			terrace_2,
			terrace_3,
			parking,
			surface,
			floor,
			exposition,
			status
		} = item;

		let itemFloor = (
			<span>
				<span className="superE">{floor}</span>
				{" étage"}
			</span>
		);
		if (Number(floor) === 0 || floor === "RDC") {
			itemFloor = "RDC";
		}
		if (Number(floor) === 1) {
			itemFloor = (
				<span>
					<span className="superER">{floor}</span>
					{" étage"}
				</span>
			);
		}
		console.log(balcony_2);
		return (
			<div className={classes.wrapper}>
				{/* <div
					className={classes.row}
				> */}
				<Item icon={SurfaceIcon} label={<span>{`${surface} m²`}</span>} />
				<Item
					icon={AnnexesIcon}
					label={
						<span className="benefitWrapper">
							{loggia && loggia.length ? (
								<span>{`Loggia: ${loggia} m²`}</span>
							) : null}
							{garden && garden.length ? (
								<span>{`Jardin: ${garden} m²`}</span>
							) : null}
							{balcony && balcony.length ? (
								<span>{`Balcon: ${balcony} m²`}</span>
							) : null}
							{balcony_2 && balcony_2.length ? (
								<span>{`Balcon 2: ${balcony_2} m²`}</span>
							) : null}
							{terrace && terrace.length ? (
								<span>{`Terrasse: ${terrace} m²`}</span>
							) : null}
							{terrace_2 && terrace_2.length ? (
								<span>{`Terrasse 2: ${terrace_2} m²`}</span>
							) : null}
							{terrace_3 && terrace_3.length ? (
								<span>{`Terrasse 3: ${terrace_3} m²`}</span>
							) : null}
							{parking && parking.length ? (
								<span>{`Parking: ${parking}`}</span>
							) : null}
						</span>
					}
				/>
				{/* </div>
					<div
					className={classes.row}
				> */}
				<Item icon={SituationIcon} label={itemFloor} />
				{exposition ? <Item icon={ExpositionIcon} label={exposition} /> : null}
				{!exposition && status ? (
					<Item icon={StatusIcon} label={status} />
				) : null}
				{/* </div> */}
			</div>
		);
	}
}

Content.propTypes = {
	classes: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired
};

export default withStyles(styles)(Content);
